

.Upload-demo{
    height:300px;
}

.ant-layout {
    display: flex;
    width: 100%;
    min-height: 100%;
  }