.content-style {
    display: flex;
    width: 100%;
    min-height: 100%;
  }


/* 菜单按钮样式 */
.menu-button{
    width: 100%;
    
}
