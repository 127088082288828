#root,body,html {
	height: 100%;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  
}

.right{
  float: right;
}

