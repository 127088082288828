.TransparentController-bar::-webkit-slider-thumb {
    appearance: none;
    width: 100%; /* 拖动按钮宽度 */
    height: 10px; /* 拖动按钮高度 */
    background-color: #1228ed; /* 拖动按钮颜色 */
    position: relative;
    z-index: 2; /* 确保拖动按钮在进度条上层 */
    /* transform: translateX(-50%); 将拖动按钮居中 */
    /* transform: rotate(90deg); 旋转90度，使其垂直显示 */
    cursor: pointer;
    left: -10px;
  }
  
  .TransparentController-bar::-webkit-slider-thumb:hover {
    background-color: #0c185e; /* 鼠标悬停时的颜色 */
  }
  
  .TransparentController-bar::-webkit-slider-thumb:active {
    background-color: #08124a; /* 鼠标长按时的颜色 */
  }

.circle-button {
  border: none;
  border-radius: 50%; /* 设置为圆形 */
  width: 20px; /* 控制圆形按钮的大小 */
  height: 20px; /* 控制圆形按钮的大小 */
  background-color: #dfdbdb; 
  cursor: pointer; /* 添加指针样式 */
}
.circle-button1 {
    border: none;
    border-radius: 50%; /* 设置为圆形 */
    width: 30px; /* 控制圆形按钮的大小 */
    height: 30px; /* 控制圆形按钮的大小 */
    background-color: #dfdbdb; 
    cursor: pointer; /* 添加指针样式 */
  }
