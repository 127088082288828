body {
    margin: 0;
    padding: 0;
    width: 100vw; /* 设置宽度为视口宽度的100% */
    height: 100vh; /* 设置高度为视口高度的100% */
    overflow: hidden; /* 防止页面溢出滚动 */
  }
  
  canvas {
    width: 100%;
    height: 100%;
    display: block; 
  }
